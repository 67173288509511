import React from 'react';
//import {Link} from 'react-router-dom';
import {NavLink} from 'react-router-dom';

class Nav extends React.Component {
    render() {
        return(
            <ul className="top-menu">

                <NavLink to="/about" activeClassName="selected">
                    <i className="fa fa-user"></i> <span> ABOUT </span>
                </NavLink>

                <NavLink to="/resume" activeClassName="selected">
                    <i className="fa fa-file-text-o"></i>  <span> RESUME </span>
                </NavLink>

                {/* <Link exact to="/about">
                <li className="active">
                    <i className="fa fa-user"></i> <span> ABOUT </span>
                </li>
                </Link> */}
                 
                {/* <Link exact to="/resume">
                <li className=""> 
                    <i className="fa fa-file-text-o"></i>  <span> RESUME </span>
                </li>
                </Link>  */}
            </ul>
        )
    }
}

export default Nav;