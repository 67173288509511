import React from 'react'

class About extends React.Component{

    getYear() {
        return new Date().getFullYear();
    }

    render() {

        return(
            <div id="about" className="content col-md-12 fadeInUp active" style={{display: "block"}}>
                              <div className="row">
                                <div className="page-title">
                                      <h2>About Me </h2>
                                    </div>                             
                      
                                    <div className="col-md-12 pinfo">
                                        <div className="col-md-12 about-info">
                                            <ul className="info-list">
                                              <li>Full Name : <span>Rahul S</span> </li>
                                                <li>Birthday : <span>30 May 1989</span> </li>
                                                <li>Job : <span>Web Developer</span> </li>
                                                {/* <li>Website : <span>www.chrisjohnson.com</span> </li> */}
                                                <li>Email : <span>rahulsasikumar89@gmail.com</span> </li>
                                                <li>Skype : <span>live:rahulwork1989</span> </li>
                                            </ul>
                                        </div> 
                                    </div>
                                    
                                    <div className="col-md-12 spotted ptext">
                                    <div className="col-md-12 about-text">
                                        <h2 className="section-title">I’m Web Developer</h2>
                                        <p>
                                        Rahul loves programming, traveling, and to take pictures. 
                                        He does not love talking about himself in the 3rd person. He is working as Senior Developer in
                                        Luminescent Digital, Technopark. Previously, he worked as a 
                                        software engineer at BGI Technologies Pvt Ltd.
                                        </p>

                                        <p>
                                         I'm a full stack developer and a photographer, skilled in PHP, HTML,
                                         CSS,CMS integration and Javascript. I create clean, professional, functional websites.
                                        </p>
                                    </div>
                                    </div>

                                    <div className="col-md-12 gray-bg ptext">
                                        <div className="col-md-6 my-skills">
                                            <ul className="skill-list">
                                                <li> 
                                                    <h3>PHP</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"90%"}}></div>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <h3>WordPress</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"95%"}}></div>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <h3>HTML5</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"100%"}}></div>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <h3>CSS</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"100%"}}></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 my-skills">
                                            <ul className="skill-list">
                                                <li> 
                                                    <h3>Javascript</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"80%"}}></div>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <h3>ReactJS</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"70%"}}></div>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <h3>NodeJS</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"30%"}}></div>
                                                    </div>
                                                </li>
                                                <li> 
                                                    <h3>Gulp.js</h3>
                                                    <div className="progress">
                                                        <div className="percentage" style={{width:"75%"}}></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> 
                                    </div>                         

                                    <div className="col-md-12 pwedo">
                                    <div className="col-md-12 col-sm-12 col-xs-12 services">
                                        {/* <div className="service col-md-4 col-sm-6">
                                            <i className="fa fa-apple"></i>
                                            <span> 
                                                <b>Applications</b>
                                                <p>Duis vitae odio gravida</p>   
                                            </span>                                  
                                        </div> */}
                                        <div className="service col-md-4 col-sm-6">
                                            <i className="fa fa-desktop"></i>
                                            <span> 
                                                <b>Web Design</b>
                                                <p>Mauris eleifend dapibus</p>   
                                            </span>                                  
                                        </div> 
                                        {/* <div className="service col-md-4 col-sm-6">
                                            <i className="fa fa-picture-o"></i>
                                            <span> 
                                                <b>Illustrations</b>
                                                <p>Posuere ac sem nec</p>   
                                            </span>                                  
                                        </div> */}
                                        <div className="service col-md-4 col-sm-6">
                                            <i className="fa fa-code"></i>
                                            <span> 
                                                <b>Development</b>
                                                <p>Curabitur euismod sapien</p>   
                                            </span>                                  
                                        </div>
                                        <div className="service col-md-4 col-sm-6">
                                            <i className="fa fa-search"></i>
                                            <span> 
                                                <b>Seo Optimization</b>
                                                <p>Etiam vitae condimentum</p>   
                                            </span>                                  
                                        </div>
                                        {/* <div className="service col-md-4 col-sm-6">
                                            <i className="fa fa-support"></i>
                                            <span> 
                                                <b>Speed Support</b>
                                                <p>Praesent nisl turpis</p>   
                                            </span>                                  
                                        </div> */}
                                    </div>
                                    </div> 
                                        
                                        <footer className="footer"> <p>  Copyright © {this.getYear()} All right reserved </p></footer>
                                                                                                      
                                    
                                </div>
                              </div>
        )
    }
}

export default About;