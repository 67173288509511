import React from 'react';

class Resume extends React.Component {
    getYear() {
        return new Date().getFullYear();
    }
render() {
    //this.props.router.push('/');
    return(
                    <div id="resume" className="content col-md-12 active">
                    	<div className="row">
                    		<div className="page-title">
                           		<h2>My Resume </h2>
                            </div>
                            <div className="col-md-12 page">
                            
                            	<div className="resume-education col-md-12">
                                	<div className="rounded-icon">
                                		<i className="fa fa-graduation-cap"></i>
                                	</div>
                                    <div className="resume-out">
                                    	<div className="resume-info">
                                        	<h2 className="info-title">Marian Engineering College</h2>
                                            <span className="info-date">2007 - 2011 </span>
                                            <p>BTech Information Technology</p>
                                        </div>
                                        <div className="resume-info">
                                        	<h2 className="info-title">Saraswathy Vidyalaya</h2>
                                            <span className="info-date">2005 - 2007 </span>
                                            <p>Pre-Degree (Maths - Computer Science)</p>
                                        </div> 
                                        <div className="resume-info">
                                        	<h2 className="info-title">Chinmaya Vidyalaya</h2>
                                            <span className="info-date">2005 Batch </span>
                                            <p>10th</p>
                                        </div> 
                                    </div> 
                                </div> 

                            	<div className="resume-education col-md-12">
                                	<div className="rounded-icon">
                                		<i className="fa fa-suitcase"></i>
                                	</div>
                                    <div className="resume-out">
                                    	<div className="resume-info">
                                        	<h2 className="info-title">Senior Software Engineer - Luminescent Digital</h2>
                                            <span className="info-date">2018 - present </span>
                                            <p>Working as Senior Developer in the company.</p>
                                        </div> 
                                        <div className="resume-info">
                                        	<h2 className="info-title">Software Engineer - BGI Technologies</h2>
                                            <span className="info-date">2011 - 2018 </span>
                                            <p>Started my career in this company. Worked as PHP Developer and Designer in the company.</p>
                                        </div> 
                                    </div>
                                </div> 
                                <footer className="footer"> <p>  Copyright © {this.getYear()} All right reserved </p></footer>
                                                                                              
                            </div>
                        </div>
                    </div>
    )
    
}
}

export default Resume;