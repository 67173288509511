import React from 'react';
import logo from './logo.svg';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import './color.css';
import Nav from './components/Nav';
import Profile from './components/Profile';
import About from './components/About';
import Resume from './components/Resume';


class App extends React.Component{
  render(){
    //const history = createBrowserHistory();
    return (
      <div className="container">
        <div className="row">
                    <Profile />
                    <div id="tab-container" className="col-md-9" data-easytabs="true">
                       <Router>
                          <Nav />
                            <Switch>
                              <Route path='/' exact component={About} />
                              <Route path='/about' exact component={About} />
                              <Route path='/resume' exact component={Resume} />
                            </Switch>
                        </Router>    
                    </div>
  
  
        </div>
      </div>
    );
  }
}


export default App;
