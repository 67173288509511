import React from 'react';
import ProfilePic from '../rahulprofilepic.jpg';

console.log(ProfilePic);

class Profile extends React.Component {
    render() {
        return(
            <div className="profile col-md-3 wow fadeInDown">
                <div className="profile-image">
                    {/* <img src="http://tavonline.net/html/picard/images/Profile-img.png" /> */}
                    <img src={ProfilePic} />
                    
                </div>
                <div className="profile-info">
                    <div className="name-job">
                        <h1>RAHUL S</h1>
                        <span className="job"> Web Developer </span>
                    </div>                       
                    <div className="social-icons">
                        <a className="facebook" target="_blank" href="https://www.facebook.com/rahul.sasikumar.3"><i className="fa fa-facebook"></i> </a>
                        <a className="twitter" target="_blank" href="https://twitter.com/rahuls_89"><i className="fa fa-twitter"></i> </a>
                        <a className="linkedin" target="_blank" href="https://www.linkedin.com/in/rahulsasikumar/"><i className="fa fa-linkedin"></i> </a>
                        <a className="instagram" target="_blank" href="https://www.instagram.com/rahuls_89/"><i className="fa fa-instagram"></i> </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;